import {
  Document,
  Page,
  Text,
  StyleSheet,
  Image,
  Font,
  View, // View import 추가
} from "@react-pdf/renderer";
import backgroundImage from "./images/completionTemplateBG.png"; // 배경 이미지
import NotoSansRegular from "./fonts/NotoSansKR-Regular.ttf";
import NotoSansBold from "./fonts/NotoSansKR-Bold.ttf";
import SpoqaHanSansNeoRegular from "./fonts/SpoqaHanSansNeo-Regular.ttf";
import SpoqaHanSansNeoThin from "./fonts/SpoqaHanSansNeo-Thin.ttf";
import { getFormattedDate } from "./utils/utils";
Font.register(
  {
    family: "Noto Sans KR",
    fonts: [
      {
        src: NotoSansRegular,
        fontWeight: "normal",
      },
      {
        src: NotoSansBold,
        fontWeight: "bold",
      },
    ],
  },
  {
    family: "Spoqa Han Sans Neo",
    fonts: [
      {
        src: SpoqaHanSansNeoRegular,
        fontWeight: "normal",
      },
      {
        src: SpoqaHanSansNeoThin,
        fontWeight: "thin",
      },
    ],
  }
);

const styles = StyleSheet.create({
  page: {
    fontFamily: "Noto Sans KR",
  },
  container: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  contentLayer: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  text: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
    color: "black",
  },
  mainTitle: {
    position: "absolute",
    left: 0, // 추가
    right: 0,
    textAlign: "center",
    color: "#000",
    letterSpacing: 1,
    fontWeight: "thin",
    fontSize: 12,
    top: "115px",
  },
  name: {
    textAlign: "center",
    color: "#000",
    letterSpacing: 20,
    fontWeight: 600,
    fontSize: 55,
    top: "220px",
  },
  contentWrapper: {
    position: "absolute",
    width: "100%",
    // top: "220px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "50px", // 이름과 내용 사이의 간격
  },
  contents: {
    textAlign: "center",
    color: "#000",
    fontSize: 12,
    fontWeight: 30,
    width: "50%",
    lineHeight: 2,
    top: "200px",
  },
  boldText: {
    fontWeight: "bold",
    fontSize: 12,
  },
  date: {
    position: "absolute",
    bottom: "115px",
    right: "110px",
    textAlign: "right",
    fontSize: 15,
    fontWeight: 80,
  },
});

export const CompletionCertificate = ({ enrollmentData }) => {
  const formattedDate = getFormattedDate();
  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.container}>
          <View style={styles.backgroundImage}>
            <Image
              src={backgroundImage}
              style={{ width: "100%", height: "100%" }}
            />
          </View>
          <View style={styles.contentWrapper}>
            <Text style={styles.mainTitle}>
              {enrollmentData.isKdc
                ? enrollmentData.courseName
                : "SPARTA CODING CLUB"}
            </Text>
            <Text style={styles.name}>{enrollmentData.name}</Text>
            <Text
              style={{
                ...styles.contents,
                width: enrollmentData.isKdc ? "70%" : "50%",
              }}
            >
              {enrollmentData.isKdc ? (
                <>
                  위 사람은{" "}
                  <Text style={styles.boldText}>
                    국민내일배움카드 K-digital 크레딧 훈련과정
                  </Text>
                  ({enrollmentData.coursePeriod})을 성공적으로 수료하였으므로
                  국민내일배움카드 운영규정 제39조 제3항에 따라 이 증서를
                  수여합니다.
                </>
              ) : (
                `위 사람은 스파르타코딩클럽에서 ${enrollmentData.courseName} (${enrollmentData.coursePeriod})을 성공적으로 수료하였으므로 이 증서를 수여합니다.`
              )}
            </Text>
          </View>
          <Text style={{ ...styles.date }}>{formattedDate}</Text>
        </View>
      </Page>
    </Document>
  );
};
