import { useEffect, useState } from "react";
import { IN_HOUSE_URL } from "../../../_consts";
import {
  fetchValidEnrollments,
  fetchValidEnrollmentsBPOnly,
  useGetCouponInfo,
} from "../../queries/mypage";
import { useSelector } from "react-redux";
import { useUser } from "../../pages/LecturePageV3/useInitialize";
import { useQuery } from "react-query";
import { useAtomValue } from "jotai";
import { academiaFFAtom } from "./flag.store";

export const useGetUrl = (page: "catalog" | "classroom") => {
  const { isLoading, isOn } = useAtomValue(academiaFFAtom);
  if (isLoading) {
    return undefined;
  }

  return {
    "catalog-new": IN_HOUSE_URL.상품탐색,
    "catalog-old": IN_HOUSE_URL.수업탐색,
    "classroom-new": IN_HOUSE_URL.신_내강의실,
    "classroom-old": IN_HOUSE_URL.구_내강의실,
  }[`${page}-${isOn ? "new" : "old"}`];
};

export const useCouponInfo = () => {
  const { isLoading, isOn } = useAtomValue(academiaFFAtom);
  const [coupons, setCoupons] = useState([]);
  const newCouponInfo = useGetCouponInfo();
  // @ts-ignore
  const oldCouponInfo = useSelector((state) => state.couponInfo);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isOn && !newCouponInfo.isLoading) {
      setCoupons(newCouponInfo.data);
    }
    if (!isOn && oldCouponInfo?.data?.coupons) {
      setCoupons(oldCouponInfo.data.coupons);
    }
  }, [isLoading, isOn, newCouponInfo.isLoading, oldCouponInfo]);

  return coupons;
};

export const useGetValidEnrollments = () => {
  const { isLoading, isOn } = useAtomValue(academiaFFAtom);
  const user = useUser();
  const userId = user?._id;
  return useQuery(
    ["validEnrollments", userId],
    () => (isOn ? fetchValidEnrollments() : fetchValidEnrollmentsBPOnly()),
    {
      enabled: !isLoading && !!userId,
    }
  );
};
