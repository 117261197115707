import { useEffect, useRef, useState } from "react";
import * as S from "./MyBasicinfo.style";
import {
  fillHyphen,
  validateBirthAndAlert,
  validateNameAndAlert,
} from "../../../../_helper";
import {
  InfoChooseButtons,
  InfoInput,
  InfoInputDisabled,
} from "../../../molecules/MyPage/MyBasicInfo/InfoInput";
import { MarketingAgreement } from "../../../molecules/MyPage/MarketingAgreement";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../../../_helpers/axios";
import { userActions } from "../../../../../_actions";
import { WithdrawalModal } from "../../../molecules/MyPage/WithdrawalModal/WithdrawalModal";
import { LoginProcess, useLockScroll } from "@teamsparta/auth-frontend";
import { useSetAmplitudeUserId } from "../../../../hooks/useSetAmplitudeUserId";
import * as CPL from "@teamsparta/cross-platform-logger";
import { consoleLogForDev } from "../../../../utils/consoleLog";
import { useHistory, useLocation } from "react-router-dom";

const genders = [
  { key: "male", label: "남자" },
  { key: "female", label: "여자" },
  {
    key: "undefined",
    label: "선택 안함",
  },
];

export const MyBasicinfo = () => {
  const { user } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);
  const [birthday, setBirthday] = useState(user.birthyear + user.birthday);
  const [gender, setGender] = useState(user.gender);

  const [originalInfo, setOriginalInfo] = useState({
    name: user.name,
    birthday: user.birthyear + user.birthday,
    gender: user.gender,
  });
  const [isSaveAvailable, setIsSaveAvailable] = useState(false);
  const [task, setTask] = useState("changeEmailEnter");
  const [isShowLoginModal, setIsShowLoginModal] = useState(false);
  const [isAborted, setIsAborted] = useState(false);
  const [withdrawalModalOpen, setWithdrawalModalOpen] = useState(false);

  useSetAmplitudeUserId(isShowLoginModal);
  useLockScroll(isShowLoginModal);
  useEffect(() => {
    if (
      name != originalInfo.name ||
      birthday != originalInfo.birthday ||
      gender != originalInfo.gender
    ) {
      setIsSaveAvailable(true);
    } else {
      setIsSaveAvailable(false);
    }
  }, [originalInfo, name, birthday, gender]);
  useEffect(() => {
    if (isAborted) {
      setIsAborted(false);
      window.location.reload();
    }
  }, [isAborted]);

  const openLoginModal = (task) => {
    setTask(task);
    setTimeout(() => {
      setIsShowLoginModal(true);
    }, 0);
  };

  const setMyPageInfo = (type, value) => {
    switch (type) {
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      default:
        alert("오류가 발생했습니다. 관리자에게 문의해주세요.");
    }
  };

  const save = async () => {
    if (!isSaveAvailable) {
      consoleLogForDev(true, "🙏 isSaveAvailable", isSaveAvailable);
      return;
    }
    if (!validateNameAndAlert(name)) return;
    if (!validateBirthAndAlert(birthday)) return;

    const reqData = {
      name,
      birth: birthday,
      gender: gender || "undefined",
    };

    try {
      const resp = await axios.post("/accounts/config", reqData, {
        withCredentials: true,
      });
      if (resp.data.ok) {
        dispatch(userActions.refreshInfo());
        setOriginalInfo({
          name,
          birthday,
          gender,
        });
        alert("변경이 완료되었습니다.");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message);
        setIsSaveAvailable(false);
      }
    }
  };

  const marketingRef = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const hasScrolled = useRef(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollTo");

    if (
      scrollTo === "marketing" &&
      marketingRef.current &&
      !hasScrolled.current
    ) {
      marketingRef.current.scrollIntoView({ behavior: "smooth" });
      hasScrolled.current = true;

      // 쿼리 파라미터 제거
      params.delete("scrollTo");
      const newSearch = params.toString();
      history.replace({
        pathname: location.pathname,
        search: newSearch ? `?${newSearch}` : "",
      });
    }
  }, [location, history]);

  return (
    <>
      <LoginProcess
        isShow={isShowLoginModal}
        setIsShow={setIsShowLoginModal}
        CPLogger={CPL}
        designatedInitialTask={task}
        setMyPageInfo={setMyPageInfo}
        setIsAborted={setIsAborted}
      />
      <S.BasicInfoWrapper>
        <S.InfoInputContainer>
          <S.BasicInfoHeader>
            <S.BasicInfoTitle>회원 정보</S.BasicInfoTitle>
            <S.SaveButton isActive={isSaveAvailable} onClick={save}>
              저장
            </S.SaveButton>
          </S.BasicInfoHeader>
          <InfoInput
            label="이름"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <InfoInput
            label="생년월일"
            type="number"
            value={birthday}
            customType="birthday"
            placeholder="예) 19910101"
            onChange={(e) => {
              setBirthday(e.target.value);
            }}
          />
          <InfoChooseButtons
            label="성별"
            options={genders}
            value={gender}
            setValue={setGender}
          />
        </S.InfoInputContainer>
        <S.Divider />
        <S.InfoInputContainer>
          <S.BasicInfoHeader>
            <S.BasicInfoTitle>계정 정보</S.BasicInfoTitle>
          </S.BasicInfoHeader>
          <InfoInputDisabled
            label="이메일"
            value={email}
            onClickChangeButton={() => {
              openLoginModal("changeEmailEnter");
            }}
          />
          <InfoInputDisabled
            label="휴대폰 번호"
            value={fillHyphen(phone)}
            onClickChangeButton={() => {
              openLoginModal("changePhoneNumberEnterAndAuth");
            }}
          />
          <InfoInputDisabled
            label="비밀번호"
            value={"보안을 위해 주기적으로 변경해 주세요."}
            onClickChangeButton={() => {
              openLoginModal("changePasswordEnter");
            }}
          />
        </S.InfoInputContainer>
        <S.Divider />
        <S.MarketingContainer ref={marketingRef} id={"marketing"}>
          <S.MarketingInnerContainer>
            <S.MarketingTitle>마케팅 수신 동의하기</S.MarketingTitle>
            <MarketingAgreement />
          </S.MarketingInnerContainer>
          <S.MarketingCaption>
            신규강의 출시, 할인쿠폰, 이벤트 등의 혜택 정보를 받을 수 있어요.
          </S.MarketingCaption>
        </S.MarketingContainer>
        <S.Divider />
        <S.BasicinfoTextContainer className={("footer", "save")}>
          <S.BasicInfoText
            onClick={() => {
              setWithdrawalModalOpen(true);
            }}
          >
            회원 탈퇴
          </S.BasicInfoText>
        </S.BasicinfoTextContainer>
        {withdrawalModalOpen && (
          <WithdrawalModal
            isOpen={withdrawalModalOpen}
            handleOpen={setWithdrawalModalOpen}
          />
        )}
      </S.BasicInfoWrapper>
    </>
  );
};
