import * as S from "./Certificates.style";
import { DownloadIcon, Receipt } from "../Icons/Icons";
import { parsePeriod } from "./Certificates";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { EnrollmentCertificate } from "../CertificateTemplates/EnrollmentTemplate";
import { CompletionCertificate } from "../CertificateTemplates/CompletionTemplate";
import { isReceiptDownloadable } from "./Certificates";
import { useReceipt } from "../../../../queries/mypage";
import { sendLogCertificate } from "../../../../queries/mypage";
export const CertificateCardMobile = ({ enrollment }) => {
  const { receiptInfo, handleReceiptClick } = useReceipt(enrollment);
  const EnrollmentCertificateContent = ({ loading }) => (
    <S.CertifiCardDownload
      isActive={enrollment.canIssueEnrollmentCertificate}
      onClick={() => sendLogCertificate("수강증", enrollment.systemType)}
    >
      {loading ? (
        <S.SolvingImg src={"/v2/assets/icons/loading_spinner.png"} />
      ) : (
        <S.IconWrapper>
          <DownloadIcon />
        </S.IconWrapper>
      )}
      <S.CertifiCardDownloadTitle>수강증</S.CertifiCardDownloadTitle>
    </S.CertifiCardDownload>
  );
  const CompletionCertificateContent = ({ loading }) => (
    <S.CertifiCardDownload
      isActive={enrollment.canIssueCompletionCertificate}
      onClick={() => sendLogCertificate("수료증", enrollment.systemType)}
    >
      {loading ? (
        <S.SolvingImg src={"/v2/assets/icons/loading_spinner.png"} />
      ) : (
        <S.IconWrapper>
          <DownloadIcon />
        </S.IconWrapper>
      )}
      <S.CertifiCardDownloadTitle>수료증</S.CertifiCardDownloadTitle>
    </S.CertifiCardDownload>
  );
  return (
    <S.CertifiCard>
      {enrollment.isCompleted ? (
        <S.CourseCompleted>수료</S.CourseCompleted>
      ) : (
        <S.CourseEnded>미수료</S.CourseEnded>
      )}
      <S.CertifiCardTitleContainer>
        <S.CertifiCardTitle>{`${enrollment.courseName}`}</S.CertifiCardTitle>
        <S.CertifiCardSubTitle>
          {parsePeriod(enrollment.startDate, enrollment.endDate)}
        </S.CertifiCardSubTitle>
        <S.CertifiCardReceipt
          onClick={handleReceiptClick}
          isActive={isReceiptDownloadable(receiptInfo)}
        >
          <S.IconWrapper>
            <Receipt />
          </S.IconWrapper>
          <S.CertifiCardReceiptTitle>영수증 발급</S.CertifiCardReceiptTitle>
        </S.CertifiCardReceipt>
        <S.CertifiCardDownloadContainer>
          {enrollment.canIssueEnrollmentCertificate ? (
            <PDFDownloadLink
              document={
                <EnrollmentCertificate
                  enrollmentData={{
                    name: enrollment.name,
                    courseName: enrollment.courseName,
                    coursePeriod: parsePeriod(
                      enrollment.startDate,
                      enrollment.endDate
                    ),
                    isKdc: enrollment.isKdc,
                  }}
                />
              }
              fileName={`수강증_${enrollment.courseName}.pdf`}
            >
              {({ loading }) => (
                <EnrollmentCertificateContent loading={loading} />
              )}
            </PDFDownloadLink>
          ) : (
            <EnrollmentCertificateContent loading={false} />
          )}
          {enrollment.canIssueCompletionCertificate ? (
            <PDFDownloadLink
              document={
                <CompletionCertificate
                  enrollmentData={{
                    name: enrollment.name,
                    courseName: enrollment.courseName,
                    coursePeriod: parsePeriod(
                      enrollment.startDate,
                      enrollment.endDate
                    ),
                    isKdc: enrollment.isKdc,
                  }}
                />
              }
              fileName={`수료증_${enrollment.courseName}.pdf`}
            >
              {({ loading }) => (
                <CompletionCertificateContent loading={loading} />
              )}
            </PDFDownloadLink>
          ) : (
            <CompletionCertificateContent loading={false} />
          )}
        </S.CertifiCardDownloadContainer>
      </S.CertifiCardTitleContainer>
    </S.CertifiCard>
  );
};
