"use client";

import { useAtomValue } from "jotai";
import { useEffect, useMemo } from "react";
import { academiaFFAtom } from "./flag.store";
import { IN_HOUSE_URL } from "../../../_consts";

export function AcademiaFFRouteGuard() {
  const pathname = window.location.pathname;
  const { isLoading, isOn } = useAtomValue(academiaFFAtom);

  const pageFrom = useMemo<"classroom" | "payment" | undefined>(() => {
    if (!pathname) return undefined;

    if (pathname.startsWith("/classroom")) {
      console.debug("classroom");
      return "classroom";
    }
    if (pathname.startsWith("/payment/")) {
      console.debug("payment");
      return "payment";
    }
    return undefined;
  }, [pathname]);

  useEffect(() => {
    if (isLoading || !isOn) return;

    if (pageFrom === "classroom") {
      window.location.replace(IN_HOUSE_URL.내강의실);
    }
    if (pageFrom === "payment") {
      window.location.replace(IN_HOUSE_URL.상품탐색);
    }
  }, [pageFrom, isLoading, isOn]);

  return null;
}
