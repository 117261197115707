import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import spartaLogo from "./images/spartaLogo.png";
import NotoSansRegular from "./fonts/NotoSansKR-Regular.ttf";
import NotoSansBold from "./fonts/NotoSansKR-Bold.ttf";
import signatureImage from "./images/signature.png";
import kdcFooterImage from "./images/kdcFooter.png";
import { getFormattedDate } from "./utils/utils";

// 폰트 등록
Font.register({
  family: "Noto Sans KR",
  fonts: [
    {
      src: NotoSansRegular,
      fontWeight: "normal",
    },
    {
      src: NotoSansBold,
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 30,
    fontFamily: "Noto Sans KR",
  },
  logo: {
    width: 76,
    height: 39,
    marginTop: 89,
    alignSelf: "center",
  },
  title: {
    color: "#000000",
    textAlign: "center",
    fontFamily: "Noto Sans KR",
    fontSize: 24,
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: 1,
    letterSpacing: 1.836,
    marginBottom: 30,
    marginTop: 20,
  },
  table: {
    width: "80%",
    margin: "0 auto",
    marginTop: 20,
    border: "1pt solid black",
    borderBottom: 0,
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1pt solid black",
  },
  tableCol1: {
    width: "30%",
    borderRight: "1pt solid black",
    padding: "8pt 10pt", // 상하 패딩 추가
    textAlign: "center",
    backgroundColor: "#F1F1F1",
  },
  tableCol2: {
    width: "70%",
    padding: "8pt 10pt", // 상하 패딩 추가
  },
  tableRowLast: {
    flexDirection: "row",
    borderBottom: "1pt solid black", // 마지막 행에도 아래 테두리 추가
  },
  cellText: {
    fontSize: 12,
    fontFamily: "Noto Sans KR",
  },
  contentsText: {
    textAlign: "center",
    fontFamily: "Noto Sans KR",
    fontSize: 14,
    fontWeight: "normal",
  },
  signatureContainer: {
    position: "relative",
    textAlign: "center",
    marginTop: 91,
  },
  signatureName: {
    fontSize: 16,
    fontFamily: "Noto Sans KR",
    fontWeight: "bold",
    letterSpacing: 0.04,
  },
  signature: {
    position: "absolute",
    width: 38, // 서명 이미지 크기 조절
    height: 38,
    right: 150, // 위치 조절
    top: -10, // 위치 조절
  },
  footerText: {
    fontSize: 12,
    fontFamily: "Noto Sans KR",
  },
  footerContainer: {
    marginTop: 48,
    textAlign: "center",
  },
  dateContainer: {
    marginTop: 48,
    textAlign: "center",
  },
  defaultText: {
    fontSize: 14,
    fontFamily: "Noto Sans KR",
  },
  contentsContainer: {
    marginTop: 68,
    textAlign: "center",
  },
  kdcFooterImage: {
    width: "184px",
    height: "24px",
    alignSelf: "center",
  },
});

const TableRow = ({ label, value, isLast = false }) => (
  <View style={isLast ? styles.tableRowLast : styles.tableRow}>
    <View style={styles.tableCol1}>
      <Text style={styles.cellText}>{label}</Text>
    </View>
    <View style={styles.tableCol2}>
      <Text style={styles.cellText}>{value}</Text>
    </View>
  </View>
);

export const EnrollmentCertificate = ({ enrollmentData }) => {
  const formattedTodayDate = getFormattedDate();

  const tableData = [
    { label: "이       름", value: enrollmentData.name },
    { label: "과       정", value: enrollmentData.courseName },
    {
      label: "날       짜",
      value: enrollmentData.coursePeriod,
    },
  ];
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* 로고 */}
        <Image src={spartaLogo} style={styles.logo} />

        {/* 제목 */}
        <Text style={styles.title}>스파르타코딩클럽 수강증</Text>

        <View style={styles.table}>
          {tableData.map((row, index) => (
            <TableRow
              key={row.label}
              label={row.label}
              value={row.value}
              isLast={index === tableData.length - 1}
            />
          ))}
        </View>

        <View style={styles.contentsContainer}>
          <Text style={styles.defaultText}>
            위 사람은 팀스파르타(주)에서 시행하는 상기 수업에 등록하였음을
            증명합니다.
          </Text>
        </View>

        <View style={styles.dateContainer}>
          <Text style={styles.defaultText}>{formattedTodayDate}</Text>
        </View>

        <View style={styles.signatureContainer}>
          <Text style={styles.signatureName}>팀스파르타(주) 대표 이범규</Text>
          <Image src={signatureImage} style={styles.signature} />
        </View>

        <View style={styles.footerContainer}>
          {enrollmentData.isKdc ? (
            <Image src={kdcFooterImage} style={styles.kdcFooterImage} />
          ) : (
            <Text style={styles.footerText}>https://spartacodingclub.kr/</Text>
          )}
        </View>
      </Page>
    </Document>
  );
};
