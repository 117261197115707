import { useGetUrl } from "../../../../businesslogics/hackle/useFeatureFlag";
import { ArrowRightSmall } from "../Icons/Icons";
import * as S from "./SayHello.style";

export const SayHello = ({ userName, showGiftTooltip }) => {
  const classroomUrl = useGetUrl("classroom");

  return (
    <S.HelloContainer>
      <S.SayHelloTitle>안녕하세요, {userName}님!</S.SayHelloTitle>
      <S.ClassroomCtaWrapper1>
        <S.ClassroomCtaWrapper2>
          <S.ClassroomCta
            onClick={() => {
              window.location.href = classroomUrl;
            }}
          >
            {showGiftTooltip && (
              <S.GiftTooltip>
                <S.GiftTooltipText>무료 강의가 지급되었어요!</S.GiftTooltipText>
              </S.GiftTooltip>
            )}
            <S.ClassroomTitle>내 강의실 바로가기</S.ClassroomTitle>
            <S.ClassroomIcon>
              <ArrowRightSmall color={"#ffffff"} />
            </S.ClassroomIcon>
          </S.ClassroomCta>
        </S.ClassroomCtaWrapper2>
      </S.ClassroomCtaWrapper1>
    </S.HelloContainer>
  );
};
