import styled from "@emotion/styled";
import { useRemoteConfig } from "@hackler/react-sdk";

export const RemoteSpartaLogo = () => {
  const remoteConfig = useRemoteConfig();
  const logoSrc = remoteConfig.get(
    "scc_seasonal_spartalog",
    "https://static.spartacodingclub.kr/TeamSparta-Inc/scc-frontend/assets/icons/logo-active.png"
  );

  return (
    <Logo
      src={"/v2/assets/images/logo_sparta.png"}
      alt="스파르타코딩클럽 로고"
    />
  );
};

const Logo = styled.img`
  width: 46px;
  /* height: 100%; */
`;
