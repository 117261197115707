import * as S from "./Gnb.mobile.style";
import React from "react";
import { GnbCategories } from "../molecules/GnbCategories.jsx";
import { GnbMyPage } from "../molecules/GnbMyPage.jsx";

import { useAtom } from "jotai";
import { LoginButton } from "../molecules/LoginButton";
import {
  isMyPageOpenAtom,
  isNavigatorOpenAtom,
} from "../../../stores/mobileMyPageTabOpen";
import {
  useGoMain,
  useRouterTitle,
} from "../../../businesslogics/_layout/gnb/v2";
import { useIsLoggedInV2 } from "../../../hooks/useSetAmplitudeUserId";
import { GnbMyPageLogo } from "../atoms/GnbMyPageLogo";
import { CloseSvg, HamburgerSVG } from "../molecules/GnbMyPage.svg";
import { connect } from "react-redux";
import { RemoteSpartaLogo } from "../../../../_components/_atoms/RemoteSpartaLogo/RemoteSpartaLogo.jsx";

const GnbMobileV3 = ({ user }) => {
  const [isNavigatorOpen, setIsNavigatorOpen] = useAtom(isNavigatorOpenAtom);
  const [isMyPageOpen, setIsMyPageOpen] = useAtom(isMyPageOpenAtom);
  const isLogin = useIsLoggedInV2();
  const goMain = useGoMain();
  const routerTitle = useRouterTitle();

  return (
    <S.Wrapper isTabOpened={isNavigatorOpen || isMyPageOpen}>
      {/*<LineBanner />*/}
      <S.TopMenuBar>
        {/* left side of GNB */}

        <S.LogoWrapper onClick={goMain}>
          <RemoteSpartaLogo />
          <S.RouterTitle>{routerTitle}</S.RouterTitle>
        </S.LogoWrapper>

        {/* right side of GNB */}
        {isLogin ? (
          <S.BtnWrapper>
            <GnbMyPageLogo />
          </S.BtnWrapper>
        ) : (
          <LoginButton />
        )}
      </S.TopMenuBar>
      <GnbCategories /> {/* left SNB */}
      <GnbMyPage user={user} /> {/* right SNB */}
    </S.Wrapper>
  );
};
function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { authentication, user };
}

const connectedGnbMobile = connect(mapStateToProps, {})(GnbMobileV3);
export { connectedGnbMobile as GnbMobileV3 };
