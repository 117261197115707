import { DisplayResolution, device } from "../../../../../_styles/device";

const { default: styled } = require("@emotion/styled");
const {
  additional,
  wBody3,
  neutralDay,
  mBody3,
} = require("@teamsparta/design-system");

export const MobileContainer = styled.div`
  padding: 4px 20px 20px 20px;
  background-color: #fff;
  ${DisplayResolution.TabletAndDesktop} {
    padding: 8px 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  padding: 12px 14px;
  gap: 6px;
  align-self: stretch;
  border-radius: 8px;
  background: ${additional.orange10};
  ${DisplayResolution.TabletAndDesktop} {
    padding: 18px 20px;
    align-items: center;
    gap: 8px;
    margin: 0;
  }
`;

export const WarnText = styled.div`
  flex: 1 0 0;
  ${mBody3};
  color: ${neutralDay.gray90};

  > b {
    ${mBody3};
    color: ${additional.orange100};
  }

  ${DisplayResolution.TabletAndDesktop} {
    ${wBody3};
    color: ${neutralDay.gray90};

    > b {
      ${wBody3};
      color: ${additional.orange100};
    }
  }
`;

export const MobileOnly = styled.div`
  display: block;
  ${DisplayResolution.TabletAndDesktop} {
    display: none;
  }
`;

export const DesktopOnly = styled.div`
  display: none;
  ${DisplayResolution.TabletAndDesktop} {
    display: block;
    display: flex;
    align-items: center;
  }
`;

export const MobileInfoCircleContainer = styled.div`
  display: flex;
  width: 16px;
  height: 21px;
  padding: 3px 0px 2px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
