import { atom } from "jotai";
import { DecisionReason } from "@hackler/react-sdk";

interface FeatureFlagState {
  isLoading: boolean;
  isOn: boolean;
  reason: DecisionReason;
}

export const academiaFFAtom = atom<FeatureFlagState>({
  isLoading: true,
  isOn: false,
  reason: "SDK_NOT_READY",
});
