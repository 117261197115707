import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useUserMetaData, useUserV2CouponCountQuery } from "../../queries/gnb";
import { academiaFFAtom } from "../hackle/flag.store";

export const useCouponCountText = (userId) => {
  const [text, setText] = useState("로딩중");
  const { isLoading, isOn } = useAtomValue(academiaFFAtom);
  const userCouponCount = useUserV2CouponCountQuery(userId);
  const userMetaData = useUserMetaData(userId);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isOn && !userCouponCount.isLoading) {
      setText(getCouponCountText(userCouponCount.data));
    }
    if (!isOn && !userMetaData.isLoading) {
      setText(userMetaData.data.coupon);
    }
  }, [isLoading, isOn, userCouponCount.isLoading, userMetaData.isLoading]);

  return text;
};

const getCouponCountText = (count) => {
  if (count === undefined) {
    return "로딩중";
  }

  if (count > 0) {
    return `${count}장`;
  }
  return "없음";
};
