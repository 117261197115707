import { useEffect, useMemo, useRef } from "react";
import videojs from "video.js";
import "videojs-landscape-fullscreen";
import {
  useIsCaptionOn,
  useIsFullscreen,
  useIsPipMode,
} from "../stores/playerAtom";
import { environment } from "../../../../../../_reducers/environment.reducer";

export const useScreenControl = (videoRef) => {
  const [isFullscreen, setIsFullscreen] = useIsFullscreen();
  const [isPipMode, setIsPipMode] = useIsPipMode();
  const [isCaptionOn, setIsCaptionOn] = useIsCaptionOn(false);
  const playerRef = useRef(null);
  const isDesktop = environment.deviceType !== "mobile";
  const isFullscreenElement =
    (document.fullscreenElement !== null) |
    (document.mozFullScreenElement !== undefined) |
    (document.webkitFullscreenElement !== null) |
    (document.msFullscreenElement !== undefined) |
    (document.webkitCurrentFullScreenElement !== null);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    playerRef.current = videojs(videoRef.current);
    const player = playerRef.current;
    player.ready(() => {
      const localCaptionV3 = localStorage.getItem("captionV3") === "true";
      handleCaption(playerRef, localCaptionV3);
      const localFullscreen = localStorage.getItem("fullscreenV3") === "true";
      if (localFullscreen) {
        player.requestFullscreen().catch((error) => {
          console.log(error);
        });
        setIsFullscreen(localFullscreen);
      }
    });

    const toggleFullscreenOnDoubleClick = () => {
      if (!player.isFullscreen()) {
        try {
          localStorage.setItem("fullscreenV3", "true");
          setIsFullscreen(true);
          player.requestFullscreen().catch((error) => {
            console.log(error);
          });
        } catch (error) {
          console.log(error);
        }
      } else if (player.isFullscreen()) {
        try {
          localStorage.setItem("fullscreenV3", "false");
          setIsFullscreen(false);
          player.exitFullscreen().catch((error) => {
            console.log(error);
          });
        } catch (error) {
          console.log(error);
        }
      }
    };
    if (isDesktop) {
      player.on("dblclick", toggleFullscreenOnDoubleClick);
    }
    //esc로 fullscreen 해제했을 경우 예외처리
    const onFullScreenChange = () => {
      setIsFullscreen(playerRef.current?.isFullscreen());
      if (!isFullscreenElement) {
        setIsFullscreen(false);
      }
      setIsFullscreen(!!isFullscreenElement);
      localStorage.setItem("fullscreenV3", !!isFullscreenElement);
    };
    const handlePipExit = () => {
      setIsPipMode(false);
    };
    document.addEventListener("fullscreenchange", onFullScreenChange);
    document.addEventListener("leavepictureinpicture", handlePipExit);

    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
      document.removeEventListener("leavepictureinpicture", handlePipExit);
      player.dispose();
      player.off("dblclick", toggleFullscreenOnDoubleClick);
    };
  }, [videoRef]);

  useEffect(() => {
    const localCaptionV3 = localStorage.getItem("captionV3") === "true";
    handleCaption(playerRef, localCaptionV3);
  }, [isCaptionOn]);

  const onFullscreenChange = () => {
    if (isFullscreen) {
      if (!isFullscreenElement) {
        setIsFullscreen(false);
        return;
      }
      playerRef.current.exitFullscreen();
      document.exitFullscreen();
      document.msExitFullscreen();
      document.mozCancelFullScreen();
      document.webkitExitFullscreen();

      localStorage.setItem("fullscreenV3", "false");
      setIsFullscreen(false);
    } else {
      playerRef.current.requestFullscreen();
      document.requestFullscreen();
      document.msRequestFullscreen();
      document.mozRequestFullScreen();
      document.webkitRequestFullscreen();

      localStorage.setItem("fullscreenV3", "true");
      setIsFullscreen(true);
    }
  };

  const handleCaption = (playerRef, isCaptionOn) => {
    setIsCaptionOn(isCaptionOn);
    const player = playerRef.current;
    if (player && player.textTracks()) {
      for (let i = 0; i < player.textTracks().length; i++) {
        let track = player.textTracks()[i];
        if (track.kind === "captions") {
          track.mode = isCaptionOn ? "showing" : "disabled";
        }
      }
    }
  };

  const onCaptionChange = (isCaptionOn) => {
    if (isCaptionOn) {
      setIsCaptionOn(false);
      localStorage.setItem("captionV3", "false");
    } else {
      setIsCaptionOn(true);
      localStorage.setItem("captionV3", "true");
    }
  };

  const onPipChange = (isPipMode) => {
    if (isPipMode) {
      setIsPipMode(
        playerRef.current?.isInPictureInPicture() && !isFullscreenElement
      );
      if (!document.pictureInPictureElement) {
        setIsPipMode(false);
        return;
      }
      playerRef?.current?.exitPictureInPicture();
      setIsPipMode(false);
    } else {
      playerRef?.current?.requestPictureInPicture();
      setIsPipMode(true);
    }
  };

  const fullScreenButtonSrc = useMemo(() => {
    if (isFullscreen) {
      return "/assets/fullscreenoff.svg";
    } else {
      return "/assets/fullscreenon.svg";
    }
  }, [isFullscreen]);

  const fullScreenButtonTooltipText = useMemo(() => {
    if (isFullscreen) {
      return "전체 화면 종료";
    } else {
      return "전체 화면";
    }
  }, [isFullscreen]);

  const captionButtonSrc = useMemo(() => {
    if (isCaptionOn) {
      return "/assets/capsbtnon.svg";
    } else {
      return "/assets/capsbtnoff.svg";
    }
  }, [isCaptionOn]);

  const captionButtonTooltipText = useMemo(() => {
    if (isCaptionOn) {
      return "자막 끄기";
    } else {
      return "자막";
    }
  }, [isCaptionOn]);

  const pipButtonSrc = useMemo(() => {
    if (isPipMode) {
      if (window.innerWidth < 768) return "/assets/mobile-pipon.svg";
      else return "/assets/pipon.svg";
    } else {
      if (window.innerWidth < 768) return "/assets/mobile-pipoff.svg";
      return "/assets/pipoff.svg";
    }
  }, [isPipMode]);

  const pipButtonTooltipText = useMemo(() => {
    if (isPipMode) {
      return "pip 모드 종료";
    } else {
      return "pip 모드";
    }
  }, [isPipMode]);

  return {
    isFullscreen,
    fullScreenButtonSrc,
    fullScreenButtonTooltipText,
    captionButtonSrc,
    captionButtonTooltipText,
    pipButtonSrc,
    pipButtonTooltipText,
    isCaptionOn,
    isPipMode,
    onFullscreenChange,
    onPipChange,
    onCaptionChange,
  };
};
