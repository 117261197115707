import classNames from "classnames/bind";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FREE_INSTALLMENT } from "../../../_consts";
import styles from "./PaymentPayMethodContainer.module.scss";
import { TossLogWarpper, TossPayIcon } from "./PaymentPayMethodContainer.style";
import { BANK_CODE } from "../../../_constants";

const cx = classNames.bind(styles);

const PaymentPayMethodContainer = ({
  method,
  toggleOptions,
  isMobile,
  course,
  isForeign,
  changeMethod,
  isShowInstallment,
  isGov,
}) => {
  const onMouseLeaveOfInstallment = (event) => {
    const { clientX, clientY } = event;
    const { top, right, bottom, left } =
      event.currentTarget.getBoundingClientRect();
    if (clientY <= top || clientX >= right) toggleOptions("installment");
  };

  useEffect(() => {
    if (isGov) {
      changeMethod(Object.keys(BANK_CODE)[0]);
    }
  }, [isGov]);

  return (
    <section className={cx("sec", "methods")}>
      <div className={cx("info-header")}>
        <div className={cx("info-header-text")}>
          <h2>결제 방법</h2>
        </div>
        {!course.is_gov && (
          <div className={cx("info-header-icon")}>
            무이자 할부 가능
            <img
              className={cx("ic-question")}
              src={`/assets/icons/ic_question.png`}
              alt="ic-question"
              onMouseOver={() => {
                toggleOptions("installment");
              }}
              onMouseLeave={(e) => {
                onMouseLeaveOfInstallment(e);
              }}
              onClick={() => {
                if (isMobile) {
                  toggleOptions("installment");
                }
              }}
            />
            {isShowInstallment && (
              <div className={cx("installment")}>
                <div
                  className={cx("installment__info")}
                  onMouseLeave={(e) => toggleOptions("installment")}
                >
                  <div className={cx("info_title")}>
                    <span>카드사 무이자 할부 안내</span>
                    <span className={cx("small")}>5만원 이상 결제시 적용</span>
                  </div>
                  <div className={cx("info_item_container")}>
                    {FREE_INSTALLMENT.map((item) => {
                      return (
                        <div key={item.card} className={cx("info_item")}>
                          <span>{item.card}</span>
                          <span>{item.installment}개월 무이자</span>
                        </div>
                      );
                    })}
                  </div>
                  <div className={cx("info_caption")}>
                    <span className={cx("small")}>
                      최종 업데이트 : 2023.11.10
                    </span>
                    <span className={cx("small")}>
                      {"최신화 정보는 [수강신청하기 클릭] >"}
                    </span>
                    <span className={cx("small")}>
                      [나이스페이 결제 정보]에서 확인 가능합니다.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={cx("info-components")}>
        <>
          {isForeign && (
            <div
              className={cx("info-component")}
              onClick={() => {
                changeMethod("paypal");
              }}
            >
              <div
                className={
                  method === "paypal" ? cx("radio", "active") : cx("radio")
                }
              >
                <div className={cx("radio-inner")} />
              </div>
              <div className={cx("method")}>
                <span className={cx("info-key")}>페이팔</span>
                <img
                  className={cx("pay-icon")}
                  src={"/assets/icons/ic_paypal.png"}
                  alt=""
                />
              </div>
            </div>
          )}

          {!isGov && (
            <div
              className={cx("info-component")}
              onClick={() => {
                changeMethod("paypal");
              }}
            >
              <div
                className={
                  method === "paypal" ? cx("radio", "active") : cx("radio")
                }
              >
                <div className={cx("radio-inner")} />
              </div>
              <div className={cx("method")}>
                <span className={cx("info-key")}>페이팔</span>
                <img
                  className={cx("pay-icon")}
                  src={"/assets/icons/ic_paypal.png"}
                  alt=""
                />
              </div>
            </div>
          )}

          {isGov && (
            <>
              {Object.keys(BANK_CODE).map((bankName) => {
                return (
                  <div
                    key={bankName}
                    className={cx("info-component")}
                    onClick={() => {
                      changeMethod(bankName);
                    }}
                  >
                    <div
                      className={
                        method === bankName
                          ? cx("radio", "active")
                          : cx("radio")
                      }
                    >
                      <div className={cx("radio-inner")} />
                    </div>
                    <div className={cx("method")}>
                      <span className={cx("info-key")}>
                        신용/체크카드 ({bankName})
                      </span>
                    </div>
                  </div>
                );
              })}
            </>
          )}

          {!course.is_gov && (
            <div
              className={cx("info-component")}
              onClick={() => {
                changeMethod("tosspay");
              }}
            >
              <div
                className={
                  method === "tosspay" ? cx("radio", "active") : cx("radio")
                }
              >
                <div className={cx("radio-inner")} />
              </div>
              <div className={cx("method")}>
                <span className={cx("info-key")}>토스</span>
                <TossLogWarpper>
                  <TossPayIcon />
                </TossLogWarpper>
              </div>
            </div>
          )}
          {/*<div*/}
          {/*  className={cx("info-component")}*/}
          {/*  onClick={() => {*/}
          {/*    changeMethod("naverpay");*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <div*/}
          {/*    className={*/}
          {/*      method === "naverpay" ? cx("radio", "active") : cx("radio")*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <div className={cx("radio-inner")} />*/}
          {/*  </div>*/}
          {/*  <div className={cx("method")}>*/}
          {/*    <span className={cx("info-key")}>네이버페이</span>*/}
          {/*    <img*/}
          {/*      className={cx("pay-icon")}*/}
          {/*      src={"/assets/icons/ic_naverpay.png"}*/}
          {/*      alt=""*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div*/}
          {/*  className={cx("info-component")}*/}
          {/*  onClick={() => {*/}
          {/*    changeMethod("kakaopay");*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <div*/}
          {/*    className={*/}
          {/*      method === "kakaopay" ? cx("radio", "active") : cx("radio")*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <div className={cx("radio-inner")} />*/}
          {/*  </div>*/}
          {/*  <div className={cx("method")}>*/}
          {/*    <span className={cx("info-key")}>카카오페이</span>*/}
          {/*    <img*/}
          {/*      className={cx("pay-icon")}*/}
          {/*      src={"/assets/icons/ic_kakaopay.png"}*/}
          {/*      alt=""*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </>

        {/*<div*/}
        {/*  className={cx("info-component")}*/}
        {/*  onClick={() => {*/}
        {/*    changeMethod("bank");*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div*/}
        {/*    className={method === "bank" ? cx("radio", "active") : cx("radio")}*/}
        {/*  >*/}
        {/*    <div className={cx("radio-inner")} />*/}
        {/*  </div>*/}
        {/*  <div className={cx("method")}>*/}
        {/*    <span className={cx("info-key")}>계좌이체</span>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </section>
  );
};

function mapStateToProps(state) {
  return {};
}
const connectedPaymentPayMethodContainer = connect(
  mapStateToProps,
  {}
)(PaymentPayMethodContainer);

export { connectedPaymentPayMethodContainer as PaymentPayMethodContainer };
